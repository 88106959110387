import { FC } from 'react'
import { TBlogPostResult } from 'common/cms-types'
import Link from 'next/link'
import { linkResolver } from '../../linkResolver'
import { Query } from '@prismicio/types'
import Carousel from 'components/Carousel'
import Card from 'components/cards/base/Card'
import Heading from 'components/Heading'
import Button from 'components/forms/Button'

type RelatedArticleType = {
  posts: Query<TBlogPostResult>
}

const BlogCarousel: FC<RelatedArticleType> = ({ posts }) => {
  if (!posts?.results?.length) return null
  return (
    <>
      <div className='blog-container overflow-x-hidden mt-xl-8 mt-3'>
        <Carousel uncontrolled slideClass={'blog-item__container'}>
          {posts.results.map((post) => (
            <Link
              className='a-unstyled'
              href={linkResolver(post as any)}
              key={post.id}
            >
              <>
                <Card padding='0' className='cursor-pointer'>
                  <div className={'blog-carrousel-image'}>
                    <img
                      loading='lazy'
                      className='img-fluid h-auto'
                      src={post.data.main_image.url}
                      alt={`${post.data.main_image.alt}`}
                    />
                  </div>
                  <div className='blog-item__content'>
                    <span className='lead text-muted d-md-none'>
                      {post.data.modified_date}
                    </span>
                    <Heading
                      className='h4 h4--mobile-small text-semibold pr-5'
                      size={3}
                    >
                      {post.data.title}
                    </Heading>
                    <span className='lead text-muted d-md-block d-none'>
                      {post.data.modified_date}
                    </span>
                  </div>
                </Card>
              </>
            </Link>
          ))}
        </Carousel>
      </div>
      <div className='d-flex justify-content-center'>
        <Link className='a-unstyled' href='/blog'>
          <Button className='mt-4 mb-md-5' size={'medium'} theme='secondary'>
            Check out our blog
          </Button>
        </Link>
      </div>
    </>
  )
}

export default BlogCarousel
