import { FC } from 'react'
import Icon from './Icon' // we need this to make JSX compile

type StarRatingType = {
  value: number
  theme?: 'dark' | 'light'
  width?: number
  height?: number
  className?: string
}

const StarRating: FC<StarRatingType> = ({
  className = 'mx-0.5',
  height = 24,
  theme = 'dark',
  value: _value,
  width = 22,
}) => {
  const value = Math.ceil(_value)
  return (
    <span>
      {[1, 2, 3, 4, 5].map((i) => {
        if (i <= value) {
          return (
            <Icon
              key={i}
              name='star'
              className={className}
              width={width}
              height={height}
              fill={theme === 'light' ? '#ffffff' : '#1D1D1F'}
            />
          )
        }
        return null
      })}
    </span>
  )
}

export default StarRating
