import { LinkResolverFunction } from '@prismicio/helpers'

/**
 * The project's Prismic Link Resolver. This function determines the URL for a given Prismic document.
 *
 * @type {prismicH.LinkResolverFunction}
 */
export const linkResolver: LinkResolverFunction = (doc) => {
  switch (doc.type) {
    case 'blog_post': {
      return `/blog/${doc.uid}`
    }
    default:
      return '/'
  }
}
