import * as React from 'react' // we need this to make JSX compile
import { FC, SVGProps } from 'react'
import cn from 'classnames'
import Strings from 'project/localisation'
import imageResize from 'project/image-proxy-web'

export type HeroLogoType = SVGProps<SVGSVGElement> & {
  color: 'white' | 'black'
  className?: string
}

const HeroLogo: FC<HeroLogoType> = ({ className, color, ...props }) => {
  return color === 'black' ? (
    <img
      loading='eager'
      src={imageResize(
        require('public/images/logos/hm-logo-black.svg'),
        null,
        null,
        'svg',
      )}
      alt={`Hoxton Mix ${Strings.logo}`}
      className={cn(className, 'img-fluid logo-img')}
    />
  ) : (
    <img
      loading='eager'
      src={imageResize(
        require('public/images/logos/hm-logo-white.svg'),
        null,
        null,
        'svg',
      )}
      alt={`Hoxton Mix ${Strings.logo}`}
      className={cn(className, 'img-fluid logo-img')}
    />
  )
}

export default HeroLogo
