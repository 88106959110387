import Card from './base/Card'
import { ReactFC } from 'common/types/ReactFC'
import React from 'react'
import { reviewsStrings } from 'common/strings/reviews-strings'
import classNames from 'classnames'
import { ColorType } from 'common/types/color-type'
import colorOrGradient from 'project/color-or-gradient'
import useWindowSize from 'components/util/useWindowSize'
import imageResize from 'project/image-proxy-web' // we need this to make JSX compile

type ReviewsCardType = {
  color?: ColorType
  color2?: ColorType
  option?: 1 | 2 | 3
  margin?: string
  rounded?: boolean
}

const ReviewsTrustCard: ReactFC<ReviewsCardType> = ({
  color = 'purple1',
  color2 = 'blue',
  margin = '',
  option = 1,
  rounded = false,
}) => {
  const size = useWindowSize()
  return (
    <div className={classNames('col-lg-6', margin)}>
      <Card
        padding='lg'
        className={classNames(
          'reviews-card px-lg-7 px-4',
          rounded ? 'rounded-box' : '',
        )}
      >
        <div className='my-auto'>
          {(option === 1 || (option === 2 && size.isLgOrHigher)) && (
            <h3
              className={classNames(
                'text-semibold display-1',
                colorOrGradient(color),
              )}
              dangerouslySetInnerHTML={{
                __html: reviewsStrings.thousandsOfCompanies,
              }}
            />
          )}
          {option === 2 && !size.isLgOrHigher && (
            <h3
              className={classNames('text-semibold display-1', 'color-purple7')}
              dangerouslySetInnerHTML={{
                __html: reviewsStrings.thousandsOfCompanies2,
              }}
            />
          )}
          {option === 3 && (
            <h3
              className={classNames(
                'text-semibold display-1',
                colorOrGradient(color),
              )}
              dangerouslySetInnerHTML={{
                __html: `${
                  reviewsStrings.thousandsOfCompaniesPart1
                } <span class="${colorOrGradient(color2)}">${
                  reviewsStrings.thousandsOfCompaniesPart2
                }</span>.`,
              }}
            />
          )}
          <img
            loading='lazy'
            className='img-fluid reviews-card__image'
            alt={'Robot Wave'}
            src={imageResize(
              require('public/images/ratings/plans-page-plugz-wave-desktop.png'),
            )}
          />
          <img
            loading='lazy'
            className='img-fluid d-block mt-3'
            width={size.isLgOrHigher ? 104 : 136}
            alt={'Google Stars Icon'}
            src={imageResize(
              require('public/images/icons/homepage-google-stars-desktop.svg'),
            )}
          />
          <img
            loading='lazy'
            className='img-fluid d-block mt-3'
            width={size.isLgOrHigher ? 170 : 157}
            alt={'Trusted Reviews Icon'}
            src={imageResize(
              require('public/images/icons/homepage-reviews-icon-desktop.svg'),
            )}
          />
        </div>
      </Card>
    </div>
  )
}

export default ReviewsTrustCard
