import {
  CSSProperties,
  ReactNode,
  TouchEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Button from './forms/Button'
import { ReactFC } from 'common/types/ReactFC'
import Icon from './Icon'
import Strings from 'project/localisation'

type ComponentType = {
  value?: number
  uncontrolled?: boolean
  children: ReactNode[]
  onChange?: (v: number) => void
  slideStyle?: CSSProperties
  slideClass?: string
}

let lastX = 0
const Carousel: ReactFC<ComponentType> = ({
  children,
  onChange: _onChange,
  slideClass,
  slideStyle,
  uncontrolled,
  value,
}) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const touchValue = useRef(0)
  const [_value, setValue] = useState(0)
  const [offset, setOffset] = useState<number>(0)
  const actualOffset = useRef(offset)
  const onChange = (v: number) => {
    if (uncontrolled) {
      touchValue.current = v
      setValue(v)
    } else {
      _onChange?.(v)
    }
  }
  const actualValue = uncontrolled ? _value : value || 0

  useEffect(() => {
    const actualValue = uncontrolled ? _value : value || 0
    const child = ref.current?.children?.[actualValue]
    const boundingRect = ref.current?.getBoundingClientRect()
    const childRect = child?.getBoundingClientRect()
    if (childRect) {
      actualOffset.current = childRect.left - boundingRect!.left
      setOffset(actualOffset.current)
    }
  }, [value, _value, uncontrolled])

  const touchMove: TouchEventHandler = useCallback((e) => {
    const currentX = e.touches[0].clientX
    const delta = currentX - lastX
    // @ts-ignore
    ref.current.style.transform = `translate3d(${-Math.max(
      0,
      actualOffset.current - delta,
    )}px, 0px, 0px)`
  }, [])

  const touchStart = useCallback((e: TouchEvent) => {
    e.preventDefault()
    ref.current?.classList.add('active')
    lastX = e.touches[0].clientX
  }, [])

  const touchEnd = () => {
    ref.current?.classList.remove('active')
    const newOffset = parseFloat(
      // @ts-ignore
      ref.current.style.transform.replace('3d', '').match(/[0-9.]+/g),
    )

    // @ts-ignore
    const values: number[] = []
    const closest: number[] = []
    const boundingRect = ref.current?.getBoundingClientRect().left
    if (!boundingRect) return
    ref.current?.childNodes.forEach((v) => {
      // @ts-ignore
      const rect = v.getBoundingClientRect().x
      const value = rect > 0 ? rect - boundingRect : rect + boundingRect * -1
      values.push(value)
      const distance =
        newOffset > 0 ? value - newOffset : value + newOffset * -1
      closest.push(distance > 0 ? distance : -distance)
    })

    const newValue = closest.indexOf(Math.min(...closest))
    onChange(newValue)
    ref.current.style.transform = `translate3d(${-Math.max(
      0,
      values[newValue],
    )}px, 0px, 0px)`
    lastX = 0
  }

  useEffect(() => {
    parentRef.current?.addEventListener('touchstart', touchStart, {
      passive: true,
    })
    parentRef.current?.addEventListener('touchend', touchEnd, {
      passive: true,
    })
    // @ts-ignore
    parentRef.current?.addEventListener('touchmove', touchMove, {
      passive: true,
    })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div ref={parentRef}>
        <ul
          style={{
            transform: `translate3d(${-Math.max(0, offset)}px, 0px, 0px)`,
          }}
          ref={ref as any}
          className='flex-row carousel list-unstyled'
        >
          {children?.map?.((c, i) => (
            <li key={i} style={slideStyle} className={slideClass}>
              {c}
            </li>
          ))}
        </ul>
        <div className='flex-row blog__controls justify-content-between align-items-center mt-lg-5'>
          <div>
            {children.map((c, i) => (
              <Button
                key={i}
                aria-label={`${Strings.page} ${i + 1}`}
                type='button'
                onClick={() => onChange(i)}
                disabled={actualValue === i}
                theme='text'
                className='btn-pagination me-3'
              />
            ))}
          </div>
          <div className='d-flex justify-content-end'>
            <Button
              aria-label={`${Strings.previous}`}
              type='button'
              onClick={() => onChange(actualValue - 1)}
              disabled={actualValue === 0}
              theme='secondary'
              className='btn-icon ms-3'
            >
              <Icon name='chevron-left' />
            </Button>
            <div className='ms-3'>
              <Button
                type='button'
                aria-label={`${Strings.next}`}
                onClick={() => onChange(actualValue + 1)}
                disabled={actualValue + 1 === children.length}
                theme='secondary'
                className='btn-icon'
              >
                <Icon name='chevron-right' />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Carousel
