import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { SolidColorType } from 'common/types/color-type'
import HeadingWithSpan from './HeadingWithSpan'

export const subtitleSizeClassNames = {
  default: 'lead-large-desktop lead-extra-large-mobile',
  large: 'h4 h4--ls-mobile-large',
}

type HeadingAndTextType = {
  title: ReactNode
  subtitle: ReactNode
  subtitleSize?: keyof typeof subtitleSizeClassNames
  condensed?: boolean
  color?: SolidColorType
  headingSize?: 1 | 2 | 3 | 4 | 5 | 6
  className?: string
  classesForTitle?: string
  classesForSubTitle?: string
  colSize?: 5 | 6 | 8 | 9 | 12
  centeredClasses?: string
}

const HeadingAndText: FC<HeadingAndTextType> = ({
  centeredClasses = 'text-center',
  children,
  className = '',
  classesForSubTitle = '',
  classesForTitle = '',
  colSize = 8,
  color = '',
  condensed,
  headingSize = 2,
  subtitle,
  subtitleSize = 'default',
  title,
}) => {
  return (
    <div className={className}>
      <HeadingWithSpan
        size={headingSize}
        className={classNames(
          `h${headingSize}`,
          classesForTitle,
          centeredClasses,
          ' mb-2',
        )}
      >
        {title}
      </HeadingWithSpan>

      {subtitle ? (
        <div className={`col-md-${colSize} mx-auto`}>
          <p
            dangerouslySetInnerHTML={{ __html: `${subtitle}` }}
            className={classNames(
              subtitleSizeClassNames[subtitleSize],
              'text-semibold',
              color ? `color-${color}` : 'text-muted',
              classesForSubTitle,
              centeredClasses,
            )}
          />
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  )
}

export default HeadingAndText
