import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'

const defaultStrings = {
  average: 'Average',
  excellent: 'Excellent',
  poweredBy: 'Powered By',
  reviews: 'Reviews',
  showMore: 'Show more',
  thousandsOfCompanies:
    '<span class="d-block">Thousands</span> <span class="d-block">of companies</span> <span class="d-block">have trusted</span> <span class="d-block">us with over</span> <span class="d-block">a million</span> <span class="d-block">pieces</span> of mail.',
  thousandsOfCompanies2:
    '<span class="d-block">Thousands</span> <span class="d-block">of companies</span> <span class="d-block">have trusted</span> <span class="d-block">us with over</span> <span class="d-block">a <span class="color-blue4">million</span></span> <span class="d-block color-blue4">pieces</span> <span class="d-block color-blue4">of mail.</span>',
  thousandsOfCompaniesPart1:
    '<span class="d-block">Thousands</span> <span class="d-block">of companies</span> <span class="d-block">have trusted</span> <span class="d-block">us with over</span> <span class="d-block">a',
  thousandsOfCompaniesPart2:
    'million <span class="d-block">pieces</span> of mail',
  verifiedBuyer: 'Verified Buyer: Yes',
  whatPeopleAreSaying: 'What people are saying',
}
export const reviewsStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
