import * as React from 'react'
import { FC } from 'react'
import Strings from 'project/localisation'
import useWindowSize from './util/useWindowSize'
import StarRating from './StarRating'
import imageResize from 'project/image-proxy-web'
import classNames from 'classnames'

export type RatingsType = {
  inlineForMobile?: boolean
  classes?: string
}

export const Ratings: FC<RatingsType> = ({
  classes = '',
  inlineForMobile = false,
  ...props
}) => {
  const size = useWindowSize()
  return (
    <>
      {!inlineForMobile && (
        <img
          loading='eager'
          alt={`ReviewsIO ${Strings.logo}`}
          height={40.52}
          src={imageResize(
            require('public/images/ratings/hoxton-mix-reviews-icon.svg'),
          )}
          className={classes}
          {...props}
        />
      )}
      {inlineForMobile && !size.isMdOrLower && (
        <img
          loading='eager'
          alt={`ReviewsIO ${Strings.logo}`}
          src={imageResize(
            require('public/images/ratings/hoxton-mix-reviews-icon.svg'),
          )}
          height={40.52}
          className={classNames('d-lg-block d-none', classes)}
          {...props}
        />
      )}
      {inlineForMobile && !size.isLgOrHigher && (
        <>
          <div className={classNames(' d-lg-none', classes)}>
            <img
              loading='eager'
              alt={`ReviewsIO ${Strings.logo}`}
              src={imageResize(
                require('public/images/ratings/reviewsio-logo.svg'),
              )}
              {...props}
            />
            <StarRating theme={'light'} width={18} height={17} value={5} />
          </div>
        </>
      )}
    </>
  )
}

Ratings.displayName = 'Ratings'
export default Ratings
