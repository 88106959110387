import cn from 'classnames'
import { ReactFC } from 'common/types/ReactFC'
import { ReactNode } from 'react'

interface HeadingWithSpan {
  children: ReactNode
  className?: string
  fontWeight?: 'bold' | 'regular' | 'light'
  font?: 'default' | 'display'
  size?: 1 | 2 | 3 | 4 | 5 | 6
}

const HeadingWithSpan: ReactFC<HeadingWithSpan> = ({
  children,
  className,
  font = 'default',
  fontWeight = 'regular',
  size = 1,
}) => {
  const CustomTag = `h${size}` as keyof JSX.IntrinsicElements
  return (
    <>
      <CustomTag
        className={cn(className, fontWeight, font)}
        dangerouslySetInnerHTML={{ __html: `${children}` }}
      />
    </>
  )
}

HeadingWithSpan.displayName = 'HeadingWithSpan'
export default HeadingWithSpan
