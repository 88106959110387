import React, { FC } from 'react'
import HeadingAndText from 'components/HeadingAndText'
import Strings from 'project/localisation'
import BlogCarrousel from 'components/blog/BlogCarousel'
import { Query } from '@prismicio/types'
import { TBlogPostResult } from 'common/cms-types' // we need this to make JSX compile

type BlogSectionType = {
  posts: Query<TBlogPostResult>
}

const BlogSection: FC<BlogSectionType> = ({ posts }) => {
  return (
    <>
      <div className='d-flex flex-column bg-grey6 pt-xxxl-20 pb-xxxl-15 py-xl-15 py-8 px-lg-0 px-4'>
        <HeadingAndText
          title={Strings.newsAndEventsBlog}
          subtitle={Strings.keepUpToDate}
          classesForSubTitle={'px-sm-0 px-5'}
        />
        <BlogCarrousel posts={posts} />
      </div>
    </>
  )
}

export default BlogSection
